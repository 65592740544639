<template>
  <div class="payrecord">
    <top-bar title="用水明细" fixed></top-bar>
    <div class="empty-record" v-if="list.length == 0">暂无记录</div>
    <div v-else>
      <van-list
          class="list-wrapper"
          v-model="isLoading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :immediate-check="false"
      >
        <section class="pay-info" v-for="(item, index) in list" :key="index">
          <div class="pay01">
            抄表日期：{{ item.chargeTime | formatDate('yyyy年MM月dd日') }}
          </div>
          <div class="pay01">
            本次水量：{{ item.amount }}
          </div>
          <div class="pay01">
            本次水费：{{ item.charge }}元
          </div>
        </section>
      </van-list>
    </div>
  </div>
</template>

<script>
import TopBar from '@/components/TopBar'
import ajax from '@/api'
import {formatDate} from '@/utils/tools'

export default {
  name: 'WaterRecord',
  inject: ['reload'],
  components: {TopBar},
  data() {
    return {
      isLoading: false,
      finished: false,
      pageNo: 0,
      pageSize: 10,
      list: [],
      bindId: this.$route.query.bindId
    }
  },
  watch: {},
  created() {
    this.loadData(this.pageNo)
  },
  filters: {
    formatDate,
  },
  methods: {
    onLoad() {
      this.loadData(this.pageNo + 1)
    },
    loadData() {
      ajax.getUseWater({
        bindId: this.bindId,
      }).then(res => {
        this.list = this.list.concat(res.data)
        this.isLoading = false
        this.pageNo = res.current
        this.finished = res.pages === res.current
      })
    }
  }
}

</script>
<style lang="scss" scoped>
@import './style.scss';

.empty-record {
  height: 100px;
  line-height: 100px;
  text-align: center;
  color: #999;
  font-size: 14px;
}

.list-wrapper {
}
</style>
